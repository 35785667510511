const canalDiccionari = {
    'tv3': {
        codi_canal: 'tv3',
        slug: 'tv3'
    },
    '324': {
        codi_canal: '324',
        slug: '324'
    },
    'esport3': {
        codi_canal: 'esport3',
        slug: 'esport3'
    },
    'sx3': {
        codi_canal: 'sx3',
        slug: 'sx3'
    },
    'c33': {
        codi_canal: 'c33',
        slug: '33',
        imatges: '',
        logo: '33',
        titol_programa: 'El canal emet de 21.30 a 05.59',
        titol_capitol: '',
        drets_cat: 'no'
    },
    'tvc': {
        codi_canal: 'tvc',
        slug: 'tvc'
    },
    'tv3cat': {
        codi_canal: 'tv3cat',
        slug: 'tv3cat'
    },
    '3cat': {
        codi_canal: '3cat',
        slug: '3cat'
    },
    'oca1': {
        codi_canal: 'oca1',
        logo: '3catexclusiudigital',
        titol_programa: "L'emissió en directe del canal exclusiu digital no està disponible en aquests moments",
        slug: 'oca1'
    },
    'oca2': {
        codi_canal: 'oca2',
        logo: '3catexclusiudigital',
        titol_programa: "L'emissió en directe del canal exclusiu digital no està disponible en aquests moments",
        slug: 'oca2'
    },
    'oca3': {
        codi_canal: 'oca3',
        logo: '3catexclusiudigital',
        titol_programa: "L'emissió en directe del canal exclusiu digital no està disponible en aquests moments",
        slug: 'oca3'
    },
    'cr': {
        codi_canal: 'cr',
        slug: 'catalunya-radio'
    },
    'ci': {
        codi_canal: 'ci',
        slug: 'catalunya-informacio'
    },
    'ocr1': {
        codi_canal: 'ocr1',
        slug: 'ocr1'
    },
    'cm': {
        codi_canal: 'cm',
        slug: 'catalunya-musica'
    },
    'icat': {
        codi_canal: 'ic',
        slug: 'icat'
    },
    'fc1': {
        codi_canal: 'fc1',
        slug: 'fc1'
    },
    'fc2': {
        codi_canal: 'fc2',
        slug: 'fc2'
    },
    'fc3': {
        codi_canal: 'fc3',
        slug: 'fc3'
    },
    'bdtv': {
        codi_canal: 'bondiatv',
        slug: 'bondiatv',
        imatges: [{ text: 'https://img.3cat.cat/multimedia/jpg/2/4/1637567121442.jpg', mida: '326x184' }],
        logo: 'bdtv',
        titol_programa: 'La plataforma de continguts audiovisuals impulsada per CVMC, EPRTVIB i CCMA',
        titol_capitol: ''
    }
};

const isCanalSlug = (canal) => {
    return Object.entries(canalDiccionari)
        .map(([key, canal]) => canal.slug)
        .includes(canal);
};

const isCodiCanal = (canal) => {
    return Object.entries(canalDiccionari)
        .map(([key, canal]) => canal.codi_canal)
        .includes(canal);
};

export { canalDiccionari, isCanalSlug, isCodiCanal };
